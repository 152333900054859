.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.list2 {
  padding: 0;
  list-style: none;
}


.card2 {
  width: 472px;
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  border-radius: .5rem;
  margin: 10px;
  padding: 25px;
  padding-bottom: 4rem;
  border: 1px solid #ced0e1;
  -webkit-appearance: none;
  -moz-appearance: none;
  
}
.card2:hover {
  transform: translateY(-2px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
  0 24px 46px var(--box-shadow-color);
  cursor: pointer;
  border-color: #f6b292;
  outline: 0;


}



.card2m {
  width: 95%;
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  border-radius: .5rem;
  padding: 25px;
  padding-bottom: 4rem;
  border: 1px solid #ced0e1;
  -webkit-appearance: none;
  -moz-appearance: none;

  margin-bottom: 1rem;
  
}
.card2m:hover {
  transform: translateY(-2px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
  0 24px 46px var(--box-shadow-color);
  cursor: pointer;
  border-color: #f6b292;
  outline: 0;
}

.Card2Title{
 margin-bottom:5px;
 font-weight: bold;
 font-size: 1.125rem;
 display: inline-block;
 word-wrap: break-word;
 max-height: 25px;
}


.Card2em{
  margin-bottom:5px;
  font-size: 1.125rem;
  overflow:hidden;
  max-height: 25px;
  display: flex;

  flex-direction: row;
  gap: 5px;
}

.Card2Ub{
  margin-bottom:5px;
  font-size: 1.125rem;
  overflow:hidden;
  max-height: 25px;
}

.Card2Ujtype{
  text-align: center;
  display: inline-block;
  padding: 8px;
  font-weight: bold;

}

.Card2Ujtype2 {

  text-align: center;
  display: inline-block;
  padding: 8px;
  margin-top: 5px;
  font-weight: bold;

}
.Card2Salari{
  text-align: center;
  margin-left: 5px;
  display: inline-block;
  padding: 8px;
  font-weight: bold;
  text-align: left;
}

.iconm{
display: inline;
margin-right: 8px;
}

.jobsinfo{
  margin-top: 1.5rem;
}

.fsdf{
  color:#f6b292;
}
.padintopbot{
  padding-top: 15px;
  padding-bottom: 15px;
}

.dateinfo{
  font-weight:bold;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 25px;
}

.jobesfinder{
  display: flex;
  flex-direction: row; 
  justify-content: center;
}

.card3 {
  width: 572px;
  height: 630px;
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  border-radius: .5rem;
  margin: 10px;
  height: 100vh;
  border: 1px solid #ced0e1;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.card32 {
  width: 572px;
  height: 630px;
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  border-radius: .5rem;
  margin: 10px;
  height: 100vh;
  border: 1px solid #ced0e1;
  position: -webkit-sticky; /* Safari */

  top: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}


.card4 {
  width: 100%;
  height: 300px;
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: rigth;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  border-radius: .5rem;
  padding: 25px;  
  -webkit-appearance: none;
  -moz-appearance: none;
}

.Card4Title{
  font-weight: bold;
  font-size: 1.125rem;
  overflow: hidden;
  max-height: 25px;
  margin-bottom:5px;
  text-align: left;
 }

 .postulbot{
  margin-top: 5px;

 }
 .scrollable-div {
  width: 100%;
  overflow: auto;
  text-align: justify;
}


.cardinsider {
  width: 100%;
  background: #fff;
  overflow: hidden;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: rigth;
  text-decoration: none;
  border-bottom:1px solid #ced0e1;
}
.subs{
  font-weight: bold;
}
.benefits{
  padding: 1px;
  text-align: center;
  display: inline-block;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ced0e1;
  margin: 5px;
  border-radius: .5rem;
}

.spiner{
  margin: 0;
  position: absolute;
  left: 44%;
  top: 50%;
  z-index: 10;
}

.spiner2{
  margin: 0;
  position: absolute;
  left: 40%;
  top: 50%;
  z-index: 10;
}
