.log{
    background-color: #f5f5f5;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.logincont{

    background-color: #FFF;
    min-width: 30rem;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 17px 10px 18px -5px rgba(0,0,0,0.1);

}

.loginlogo{
    width: 10rem;
}
.logocontainter4{
    text-align: center;
}
.mrgnbtm{
    margin-bottom: 1rem;
}
.btnlogin{
    width: 100%;
    text-align: center;
}
.ntnwdt{
    width: 100%;
}






.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}