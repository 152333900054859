.formTitle{
        color: #FB9C00;
        font-size: 1.5rem;
        font-weight: bold;
        display: flex;
        align-items: center;    

        gap: 1rem;
}
.smal {
    height: 2rem;
    width: 100%;
}
.barrcontainer{
    width: 80%;
    z-index: 5;
}

.bigger {

    height: 15rem;
    width: 89%;
}
.form-group{
    margin-bottom: .8rem;

}

.inputsm{
    font-weight: bold;
}

.inputsm span{
    font-weight: lighter;
    font-size: small;
    margin-left: 5px;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
.sider-cont{
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}



.sider{
    width: 85%;
}
/* dinamyc form */
.dy-form{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top:sma .5rem;
    margin-bottom: .5rem;
}
.addFases{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    
}

.addFases:hover{
    cursor: pointer;
}

.deleteicon:hover{
    cursor: pointer;
}

/* dynamic form jobs */

.smal2 {
    height:5px;
    width: 100%;
}
.dy-form-j2{
    width: 80%;
}
.dy-form-j{
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 1rem;
}

.inner-form{
    display: grid;
    grid-template-columns: 1fr 1fr;

}
/* bacl arrow */

.back{
    background-color: #FB9C00;
    border-radius: 2px;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: small;
    cursor: pointer;
    border-radius: 1rem;
}


.form-control2{
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1c1e2e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced0e1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
}

.smal2 {
    min-height: 3rem;
    width: 100%;
}

.containerForm{
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 41.9rem;   
}

.containerForml{
   margin: 2rem;
}



.site-logo .logo-text2 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold
}
.art{
    background-color: #FB9C00;
    width: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    border-radius:0 1rem 1rem 0;
    box-shadow: 17px 10px 18px -5px rgba(0,0,0,0.1);
}

.josblogoContainer{
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
} 
.jobslogo{
    width: 70%;
}

.notFound{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notFound img{
 width: 30rem;
 margin-bottom: 1rem;
}