/* Google Font Import - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

:root {
    /* ===== Colors ===== */
    --body-color: #F7F9FB;
    --sidebar-color: #FFF;
    --primary-color: #695CFE;
    --primary-color-light: #F6F5FF;
    --toggle-color: #DDD;
    --text-color: #707070;

    /* ====== Transition ====== */
    --tran-03: all 0.2s ease;
    --tran-03: all 0.3s ease;
    --tran-04: all 0.3s ease;
    --tran-05: all 0.3s ease;
}

.body2 {
    min-height: 100vh;
    background-color: var(--body-color);
    transition: var(--tran-05);
}

::selection {
    background-color: var(--primary-color);
    color: #fff;
}

.body.dark {
    --body-color: #18191a;
    --sidebar-color: #242526;
    --primary-color: #3a3b3c;
    --primary-color-light: #3a3b3c;
    --toggle-color: #fff;
    --text-color: #ccc;
}

/* ===== Sidebar ===== */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    padding: 10px 14px;
    background: var(--sidebar-color);
    transition: var(--tran-05);
    z-index: 100;
}

.sidebar.close {
    width: 88px;
}

/* ===== Reusable code - Here ===== */
.sidebar li {
    height: 50px;
    list-style: none;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.sidebar header .image,
.sidebar .icon {
    min-width: 60px;
    border-radius: 6px;
}

.sidebar .icon {
    min-width: 60px;
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.sidebar .text,
.sidebar .icon {
    color: var(--text-color);
    transition: var(--tran-03);
}

.sidebar .text {
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;
    opacity: 1;
}

.sidebar.close .text {
    opacity: 0;
}

/* =========================== */

.sidebar header {
    position: relative;
}

.sidebar header .image-text {
    display: flex;
    align-items: center;
}

.sidebar header .logo-text {
    display: flex;
    flex-direction: column;
}

header .image-text .name {
    margin-top: 2px;
    font-size: 18px;
    font-weight: 600;
}

header .image-text .profession {
    font-size: 16px;
    margin-top: -2px;
    display: block;
}

.sidebar header .image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar header .image img {
    width: 40px;
    border-radius: 6px;
}

.sidebar header .toggle {
    position: absolute;
    top: 50%;
    right: -25px;
    transform: translateY(-50%) rotate(180deg);
    height: 25px;
    width: 25px;
    background-color: var(--primary-color);
    color: var(--sidebar-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
    transition: var(--tran-05);
}

body.dark .sidebar header .toggle {
    color: var(--text-color);
}

.sidebar.close .toggle {
    transform: translateY(-50%) rotate(0deg);
}

.sidebar .menu {
    margin-top: 40px;
}

.sidebar li.search-box {
    border-radius: 6px;
    background-color: var(--primary-color-light);
    cursor: pointer;
    transition: var(--tran-05);
}

.sidebar li.search-box input {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    background-color: var(--primary-color-light);
    color: var(--text-color);
    border-radius: 6px;
    font-size: 17px;
    font-weight: 500;
    transition: var(--tran-05);
}

.sidebar li a {
    list-style: none;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    text-decoration: none;
    transition: var(--tran-03);
}

.sidebar li a:hover {
    background-color: var(--primary-color);
}

.sidebar li a:hover .icon,
.sidebar li a:hover .text {
    color: var(--sidebar-color);
}

body.dark .sidebar li a:hover .icon,
body.dark .sidebar li a:hover .text {
    color: var(--text-color);
}

.sidebar .menu-bar {
    height: calc(100% - 55px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
}

.menu-bar::-webkit-scrollbar {
    display: none;
}

.sidebar .menu-bar .mode {
    border-radius: 6px;
    background-color: var(--primary-color-light);
    position: relative;
    transition: var(--tran-05);
}

.menu-bar .mode .sun-moon {
    height: 50px;
    width: 60px;
}

.mode .sun-moon i {
    position: absolute;
}

.mode .sun-moon i.sun {
    opacity: 0;
}

body.dark .mode .sun-moon i.sun {
    opacity: 1;
}

body.dark .mode .sun-moon i.moon {
    opacity: 0;
}

.menu-bar .bottom-content .toggle-switch {
    position: absolute;
    right: 0;
    height: 100%;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
}

.toggle-switch .switch {
    position: relative;
    height: 22px;
    width: 40px;
    border-radius: 25px;
    background-color: var(--toggle-color);
    transition: var(--tran-05);
}

.switch::before {
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    background-color: var(--sidebar-color);
    transition: var(--tran-04);
}

body.dark .switch::before {
    left: 20px;
}

.home {
    position: absolute;
    top: 0;
    top: 0;
    left: 250px;
    min-height: 70rem;
    width: calc(100% - 250px);
    background-color: var(--body-color);
    transition: var(--tran-05);
}



.home .text {
    font-size: 20px;
    font-weight: 500;
    color: var(--text-color);
    padding: 12px 60px;
}

.sidebar.close~.home {
    left: 78px;
    height: 100vh;
    width: calc(100% - 78px);
}

body.dark .home .text {
    color: var(--text-color);
}

.menu-links {
    margin: 0;
    padding: 0;
}

.chartscontainer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    gap: 20px;

}

.cardChart {
    background-color: #FFF;
    box-shadow: 17px 10px 18px -5px rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    width: 40%;
    padding: 1rem;
    overflow: hidden;
}


.cardChart2 {
    background-color: #FFF;
    box-shadow: 17px 10px 18px -5px rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    width: 60%;
    padding: 1rem;
}

.cardChart3 {
    background-color: #FFF;
    box-shadow: 17px 10px 18px -5px rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    width: 30%;
    padding: 1rem;
    overflow: hidden;
}


.cardChart4 {
    background-color: #FFF;
    box-shadow: 17px 10px 18px -5px rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    width: 70%;
    padding: 1rem;
}

.pieDiv{
    width: 60%;
    max-height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homes {
    position: absolute;
    top: 0;
    top: 0;
    left: 250px;
    height: 100vh;
    width: calc(100% - 250px);
    background-color: var(--body-color);
    transition: var(--tran-05);
}


.homes2 {
    position: absolute;
    top: 0;
    top: 0;
    left: 250px;
    height: 120rem;
    width: calc(100% - 250px);
    background-color: var(--body-color);
    transition: var(--tran-05);
}

.homes .text {
    font-size: 20px;
    font-weight: 500;
    color: var(--text-color);
    padding: 12px 60px;
}

.homes .text2 {
    font-size: 20px;
    font-weight: 500;
    color: var(--text-color);
    padding: 12px 60px;
}


.homes2 .text2 {
    font-size: 20px;
    font-weight: 500;
    color: var(--text-color);
    padding: 12px 60px;
}

.sidebar.close~.homes {
    left: 78px;
    height: 100vh;
    width: calc(100% - 78px);
}

body.dark .homes .text {
    color: var(--text-color);
}


/* register styles  */

.registercontainer{

    padding: 2rem;
    width: 95%;
    height: 90%;
    background-color: #FFFF;
    box-shadow: 17px 10px 18px -5px rgba(0,0,0,0.1);
    border-radius: .5rem;
    overflow: hidden;
}


.registercontainer2{
    margin: 0;
    width: 95%;
    height: 90%;
}

.registerE {
    height: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border-bottom: 1px solid #a19b9b;
    overflow: hidden;
}

.reg-Tex{
    font-size: .4rem;
    font-weight: 500;
    color: #000;
    padding: 0.5rem 0;
    text-align: center;
}


.registerE .reg-Tex span{
    font-size: .4rem;
    font-weight: 500;
    color: #000;
    padding: 0.5rem 0;
    text-align: center;
}

.addJobs{
    background-color: #695CFE;
    padding: 10px;
    color: #FFF;
    border-radius: 6px;
    font-size: 1rem;
    font-weight:bold;
    border: none;
    text-decoration: none;
    display: block;
    width: fit-content;
    margin-bottom: 1rem;
}
.addJobs:hover{
    color: #fff;
}
.saveJob:hover{
    cursor: pointer;
}