.logoIno{
    height: 75px;
}
.titleI{
    color: #02AEF0;
}




@media (min-width: 400px) {

    .lap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }
}

@media (min-width: 576px) {

    .lap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }
}

@media (min-width: 768px) {
    .lap {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 1400px) {
    .lap {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}



.iconchek {
    margin-right: .5rem;
}

.team{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

}


button.rec-dot{
    background-color: #FFF;
    box-shadow: 0 0 1px 3px  #FB9C00;

  }

.rec-dot_active {
    background-color: #FFF !important;
    box-shadow: 0 0 1px 3px  #FFF  !important;
}

.rec.rec-arrow{
    visibility: hidden;
}
