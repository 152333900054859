.modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 30px;
    border: 1px solid #888;
    max-width: 40rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .imgupload{
    max-width: 40%;
    margin:1rem;
  }

.btnsup{
    width: 100%;
    display: flex;
    justify-content:space-evenly;
}
.croz{
    width: 100%;
    text-align: end;
    font-size: 1.5rem;
}
.croz:hover{
    cursor: pointer;
}

